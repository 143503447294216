html, body { height: 100%; }
body { margin: 0; 
  font-family: "Poppins", Sans-serif !important;  
  /*font-family: "Helvetica Neue", sans-serif; */
}

:root {
  --theme-default: #01327d;
}

.cursor-pointer{
  cursor: pointer;
}

.pf-btn {
  line-height: 20px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.5rem;
  transition: 0.3s ease-in-out;
}

.pf-btn-solid {
  padding: 15px 50px;
  color: #ffffff !important;
  letter-spacing: 0.05em;
  border: 2px solid var(--theme-default) !important;
  border-radius: 2rem;
  background-image: linear-gradient(
    30deg,
    var(--theme-default) 50%,
    transparent 50%
  ) !important;
  background-size: 850px;
  background-repeat: no-repeat;
  background-position: 0;
  transition: background 300ms ease-in-out;
}
.pf-btn-solid:hover {
  background-position: 100%;
  color: #000000 !important;
  background-color: #ffffff;
}

.mat-mdc-card-title{
  font-size: 24px;
  font-weight: 500;
}

/*override for all tables*/
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th
{
  border: 1px solid var(--surface-300);
  border-width: 0 0 1px 0;
  padding: 0.5rem 0.5rem;
}

.p-paginator-element, .p-paginator .p-dropdown .p-dropdown-label {
  font-size: 0.75rem;
}

.p-inputtext, .p-multiselect .p-multiselect-label.p-placeholder, .p-multiselect .p-multiselect-label, .p-multiselect-panel .p-multiselect-items .p-multiselect-item{
  font-size: 0.85rem;
}
